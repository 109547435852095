export const questionFoodList = [
  {
    topic: "dishByCountry",
    type: "country",
    isDistractor: false,
  },
  {
    topic: "dishByCountry",
    type: "country",
    isDistractor: true,
  },
  {
    topic: "dishByCountry",
    type: "country",
    isDistractor: false,
  },
  {
    topic: "dishByCountry",
    type: "country",
    isDistractor: true,
  },
  {
    topic: "dishByCountry",
    type: "country",
    isDistractor: true,
  },
  {
    topic: "dishByCountry",
    type: "country",
    isDistractor: false,
  },
];
