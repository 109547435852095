export const questionAllList = [
  {
    topic: 'actorByCountry',
    type: 'country',
    isDistractor: false,
  }, 
  {
    topic: 'logoByCountry',
    type: 'country',
    isDistractor: false,
  },
  // {
  //   topic: 'logoByCountry',
  //   type: 'country',
  //   isDistractor: true,
  // }, 
  //  {
  //   topic: 'bandByCountry',
  //   type: 'country',
  //   isDistractor: true,
  // }, 
  // {
  //   topic: 'bandByCountry',
  //   type: 'country',
  //   isDistractor: false,
  // },
  // {
  //   topic: 'capitalByCountry',
  //   type: 'country',
  //   isDistractor: true,
  // }, 
  {
    topic: 'capitalByCountry',
    type: 'country',
    isDistractor: false,
  },
   {
    topic: 'townByCountry',
    type: 'country',
    isDistractor: true,
  }, 
  {
    topic: 'dishByCountry',
    type: 'country',
    isDistractor: false,
  },
  {
    topic: 'townByCountry',
    type: 'country',
    isDistractor: false,
  },
  {
    topic: 'actorByCountry',
    type: 'country',
    isDistractor: true,
  }, 
  {
    topic: 'dishByCountry',
    type: 'country',
    isDistractor: false,
  },
  {
    topic: 'actorByCountry',
    type: 'country',
    isDistractor: false,
  },
    {
        topic: 'personByCountry',
        type: 'country',
        isDistractor: true,
      },
   {
    topic: 'animalByCountry',
    type: 'country',
    isDistractor: false,
  }, 
  {
    topic: 'filmByCountry',
    type: 'country',
    isDistractor: true,
  }, 
  {
    topic: 'animalByCountry',
    type: 'country',
    isDistractor: true,
  },
        
        {
          topic: 'filmByCountry',
          type: 'country',
          isDistractor: false,
        },
        {
          topic: 'townByCountry',
          type: 'country',
          isDistractor: false,
        }, 
        {
          topic: 'footballerByCountry',
          type: 'country',
          isDistractor: false,
        }, 
    //     {
    //       theme: 'townByCountry',
    //       type: 'country',
    //       isDistractor: true,
    //     }, 
    //     {
    //       theme: 'townByCountry',
    //       type: 'country',
    //       isDistractor: false,
    //     }, 
    //     {
    //       theme: 'dishByCountry',
    //       type: 'country',
    //       isDistractor: true,
    //     },    
    //     {
    //     theme: 'location',
    //     type: 'country',
    //     isDistractor: true,
    //   },
    //   {
    //     theme: 'personByCountry',
    //     type: 'country',
    //     isDistractor: true,
    //   },
     
    //   {
    //     theme: 'location',
    //     type: 'region',
    //     isDistractor: true,
    //   },
      
    // {
    //     theme: 'location',
    //     type: 'place',
    //     isDistractor: true,
    //   },
     
    // {
    //     theme: 'personByCountry',
    //     type: 'country',
    //     isDistractor: true,
    //   },
    //   {
    //     theme: 'animalByCountry',
    //     type: 'country',
    //     isDistractor: true,
    //   },
    //   {
    //     theme: 'animalByCountry',
    //     type: 'country',
    //     isDistractor: true,
    //   },

      // {
      //   theme: 'footballerByCountry',
      //   type: 'country',
      //   isDistractor: false,
      // },
    //   {
    //     theme: 'location',
    //     type: 'country',
    //     isDistractor: false,
    //   },
      // {
      //   theme: 'actorByCountry',
      //   type: 'country',
      //   isDistractor: false,
      // },
      // {
      //   theme: 'personByCountry',
      //   type: 'country',
      //   isDistractor: true,
      // },
      // {
      //   theme: 'personByCountry',
      //   type: 'country',
      //   isDistractor: false,
      // },
    //   {
    //     theme: 'filmByCountry',
    //     type: 'country',
    //     isDistractor: false,
    //   },
    //   {
    //     theme: 'actorByCountry',
    //     type: 'country',
    //     isDistractor: true,
    //   },

    //   {
    //     theme: 'dishByCountry',
    //     type: 'country',
    //     isDistractor: false,
    //   },
 
    //   {
    //     theme: 'filmByCountry',
    //     type: 'country',
    //     isDistractor: false,
    //   },

    // {
    //   theme: 'personByRegion',
    //   type: 'region',
    //   isDistractor: false,
    // },
    // {
    //   theme: 'personByRegion',
    //   type: 'region',
    //   isDistractor: true,
    // },
    // // {
    // //   theme: 'musicianByCountry',
    // //   type: 'country',
    // //   isDistractor: false,
    // // }
    // {
    //     theme: 'end',
    //     type: 'end',
    //     isDistractor: true,
    // }
  ];