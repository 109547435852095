export const questionEnterpriseList = [
  {
    topic: "logoByCountry",
    type: "country",
    isDistractor: false,
  },
  {
    topic: "logoByCountry",
    type: "country",
    isDistractor: true,
  },
  {
    topic: "logoByCountry",
    type: "country",
    isDistractor: false,
  },
  {
    topic: "logoByCountry",
    type: "country",
    isDistractor: true,
  },
  {
    topic: "logoByCountry",
    type: "country",
    isDistractor: true,
  },
  {
    topic: "logoByCountry",
    type: "country",
    isDistractor: false,
  },
];
